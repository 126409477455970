.block {
    height: auto; 
    border: solid 1px rgba(0, 0, 0, 0);
}
.block:hover {
    border: solid 1px rgba(0, 0, 0, 0.212);
}


.block img {
    max-width: 300px;
    width: 100%;
}

h5 {
    color: white;
    margin-left: 10px;
    padding-top: 10px;
}

.pText {
    color: white;
    margin-left: 10px;
    margin-top: -10px;
}

.block a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    
}
.block a:hover {
    text-decoration: none;
    color: white;
}

.divText {
    height: auto;
    background-color: rgba(0, 0, 0, 0.267); 
}

@media screen and (max-width: 576px){
    .hiddenP {
        display: block;
        padding: 0;
        margin-left: 10px;
        padding-top: 10px;
    }
    .hiddenH5 {
        display: none;
    }

    .divText {
        min-height: 130px;
    }
}

@media screen and (min-width: 576px){
    .hiddenP {
        display: none;
    }
    .hiddenH5 {
        display: block;
    }
    .divText {
        min-height: 110px;
    }
}

@media screen and (min-width: 922px){
    .divText {
        min-height: 90px;
    }
}